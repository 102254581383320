export const SCHEDULE_BASE_ROW_HEIGHT = 36;
export const SCHEDULE_ROW_HEIGHT = 37;
export const SCHEDULE_ROW_HEIGHT_MARGIN = 3;
export const DEPARTMENT_HEADER_ROW_HEIGHT = 44;
export const DEPARTMENT_PLACEHOLDER_HEIGHT = 12;
export const SCHEDULE_MIN_FILLER_HEIGHT = 80;

export const SCHEDULE_ITEM_SIZE = 37;
export const START_SIZE = 37;
export const TEAM_DEPARTMENT_ROW_ITEM_MARGIN = 4;
export const HOLIDAY_ITEM_SIZE = 15;

export const GENERIC_SIZE = 20;
export const START_EMPLOYEE_ITEM_SIZE = 37;
export const TOTALS_SIZE = 36;

export const WEATHER_ROW_HEIGHT = 71;

export const ADJUSTED_TIMESHEET_TOTALS_ROW_HEIGHT = 42;
